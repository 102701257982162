import image1 from "../../../assets/avatares/dependents/1.png"
import image2 from "../../../assets/avatares/dependents/2.png"
import image3 from "../../../assets/avatares/dependents/3.png"
import image4 from "../../../assets/avatares/dependents/4.png"
import image5 from "../../../assets/avatares/dependents/5.png"
import image6 from "../../../assets/avatares/dependents/6.png"

export const dataRelatives = {
    group: 'Familiares',
    data: [
        {
            url: image1,
            name: "Maria Clara",
            sex: "F",
            status: "aprovado",
            dependent: {
                status: true,
                hidden: false,
                dependents: [
                    {
                        date: "22/06/2022",
                        exam: "Consulta - pediatra",
                        professional: "Doutora Mariza Medeiros",
                    },
                    {
                        date: "14/05/2022",
                        exam: "Exames locais ortopédicos",
                        professional: "Doutor Carlos Quintana",
                    },
                ]
            },
            status_service: "registro",
            action: "marcar"
        },
        {
            url: image2,
            name: "Augusto",
            sex: "M",
            status: "aprovado",
            dependent: {
                status: true,
                hidden: false,
                dependents: [
                    {
                        date: "22/06/2022",
                        exam: "Exames locais ortopédicos",
                        professional: "Doutor Carlos Quintana",
                    },
                    {
                        date: "20/06/2022",
                        exam: "Exames gerais de rotina",
                        professional: "Doutor Marcos Gomes",
                    },
                ]
            },
            status_service: "registro",
            action: "marcar"
        },
    ]
}