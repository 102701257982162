<template>
    <div class="dependents">
        <div class="item" v-for="dep in item.dependent.dependents">
            <div class="left">
                <div class="date">
                    <img src="../../../../assets/icons/date-dependent.png" alt="" />
                    <span>{{dep.date}}</span>
                </div>
                <div class="type">
                    <img src="../../../../assets/icons/type-dependent.png" alt="" />
                    <span>{{dep.exam}}</span>
                </div>
            </div>
            <div class="right">
                <div class="professional">
                    <img src="../../../../assets/icons/professional.png" alt="" />
                    <span>{{dep.professional}}</span>
                </div>
                <div class="more">
                    <span>Ver mais</span>
                    <img src="../../../../assets/icons/arrow-right-blue.png" alt="" />
                </div>
            </div>
        </div>
        <Button class="align-self-end" v-if="item.dependent.dependents.length > 0">
            Ver todos registros financeiros
            <img src="../../../../assets/icons/view-blue.png" alt="" />
        </Button>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    props: ["item"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.dependents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: white;
    margin-top: -4px;
    border-radius: 0 0 8px 8px;
    padding: 12px;
    padding-top: 16px;
    z-index: 1;
    gap: 8px;
    .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #BDBDBD;
        width: 100%;
        padding: 8px;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        .left{
            display: flex;
            align-items: center;
            gap: 26px;
        }
        .right{
            display: flex;
            align-items: center;
            gap: 130px;
        }
        img{
            width: max-content;
            height: max-content;
        }
        .date{
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .type{
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .professional{
            display: flex;
            align-items: center;
            gap: 10px;
            min-width: 190px;
        }
        .more{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
            color: #2F80ED;
            cursor: pointer;
        }
    }
}

::v-deep(button) {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2D9CDB;
    width: max-content;
    height: 40px;
    background: white;
    border: 1px solid #2D9CDB !important;
    border-radius: 100px;
    height: 26px;
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    box-shadow: none;
    margin-top: 18px;
    &:hover {
        background: white !important;
        color: #2D9CDB !important;
    }
}
</style>